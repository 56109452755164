<template>
  <div class="input-down absolute" :class="[showSearchTip?'show':'']">
    <div v-if="showReacommedEstate && recommendEstate.length">
      <div class="searchMsgTitle flex sb_center">
        <span class="searchMsgName">推荐楼盘</span>
      </div>
      <ol class="flex wrap pb10">
        <li class="hot-search-list-item" v-for="estate in recommendEstate" :key="estate.guid">
          <router-link target="_blank" :to="{path:'newEstateDetail',query: { guid: estate.guid }}" class="black">{{estate.name}}</router-link>
        </li>
      </ol>
    </div>
    <div v-if="isShowSaveSerch" class="searchMsgTitle flex sb_center">
      <span class="searchMsgName">已保存搜索</span>
      <div class="searchMsgTitleRightPart">
        <a href="javascript:;" @click="enterSetting" class="manage">管理</a>
      </div>
    </div>
    <div v-else-if="showResult" class="searchMsgTitle">
      <span class="searchMsgName">你可能在找</span>
    </div>
    <div v-else-if="showHistory && historyList.length" class="searchMsgTitle flex sb_center">
      <span class="searchMsgName">搜索历史</span>
      <div class="searchMsgTitleRightPart">
        <a @click="clearHistory" href="javascript:;" class="manage">清除历史记录</a>
      </div>
    </div>
    <ul v-if="isShowSaveSerch" class="save-wrap">
      <li v-for="(search,index) in searchRecords" class="ellipsis cursor" :key="index" @click="handleSaveSearchItem(index)">
        <ul class="flex">
          <li v-for="(cur,i) in search.filter((_,_i) => _i)" :key="i" class="flex pr5">
            <span v-if="cur.key == 'city'">{{cur.value.name}}</span>
            <div v-else class="flex">
              <span v-for='(item,itemIndex) in cur.data' :key='itemIndex'>{{item.name }}{{itemIndex !== cur.data.length - 1 ? "、":""}}</span>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <ul v-else-if="showResult" v-infinite-scroll="loadMore" infinite-scroll-immediate="false">
      <li :class="{'search-result-list':tradeType ===3}" v-for="(item,index) in estates" class="infinite-list-item" :key="index" @click="onClickHistory(item,1)">
        <a class="inline-b" v-if="tradeType !== 3" href="javascript:;">
          <span class="msgListTitle">
            <span class="sug_region">小区</span>
            <span class="historyKey" v-html="highlight(item.name)">
            </span>
            <span class="sub-text">
              {{item.address}}
            </span>
          </span>
          <span v-if="tradeType === 2 ||  tradeType === 4" class="msgListAdd"> {{ item.saleCount ?`在售${item.saleCount}套` : '暂无房源'}}</span>

          <span v-else class="msgListAdd"> {{ item.rentCount ?`在租${item.rentCount}套` : '暂无房源'}}</span>
        </a>

        <router-link v-else target="_blank" :to="{path:'newEstateDetail',query: { guid: item.guid }}" class="search-result flex sb_center" style="display:flex">
          <div class="flex1 flex">
            <span class="resblock-name inline-b ellipsis" style="max-width:85px">{{item.name}}</span>
            <span class="area clo84 flex1 inline-b ellipsis" style="max-width:160px">{{item.address}}</span>
          </div>
          <div class="desc flex s_center">
            <span class="price">
              {{"均价" + item.averagePrice + "/㎡"}}
            </span>
            <span class="resblock-type">{{item.propertyTypeDesc || "楼盘"}} </span>
          </div>
        </router-link>
      </li>
      <load-more :no-more="noMore" />
    </ul>
    <ul v-else-if="showHistory && historyList.length">
      <li v-for="(item,index) in historyList" @click.stop="onClickHistory(item,2)" :key="index">
        <a class="search-result black" href="javascript:;">
          {{ item.title || item.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import LoadMore from "./load-more.vue";
import { getItem, setItem, removeItem } from "@/utils/storage.js";
import Constant from "@/common/Constant.js";
import houseServer from "@/services/houseServer.js";
import tool from "@/utils/tool.js";
import { mapState } from "vuex";

let that;
export default {
  props: {
    /*
            1:租房
            2:二手房
            3:新房
            4:小区
        */
    tradeType: {
      type: Number,
      required: true
    },
    showSearchTip: {
      type: Boolean,
      default: false
    },
    estateName: {
      type: String,
      required: true
    },
    showReacommedEstate: {
      type: Boolean,
      default: false
    },
    showSaveSerch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showResult: false, //显示结果
      showHistory: true, //显示历史搜索
      searchParams: {
        page: 1,
        itemsPerPage: 20
      },
      noMore: false,
      isloading: false,
      estates: [],
      historyList: [],
      recommendEstate: []
    };
  },
  watch: {
    estateName: tool.throttle(val => {
      if (!val || !val.trim()) {
        that.showResult = false;
        that.showHistory = true;
        that.historyList = that.getHistoryList();
        return;
      }
      that.showResult = true;
      that.searchParams.page = 1;
      that.searchParams.cityCode = that.location.cityCode;
      that.noMore = false;
      if (that.tradeType != 3) {
        that.searchParams.estateName = val;
      } else {
        that.searchParams.name = val;
      }
      that.searchEstate();
    }, 400),
    tradeType(newVal, prev) {
      if (newVal !== prev) {
        this.searchParams.page = 1;
        this.searchEstate();
        this.estates = [];
      }
    }
  },
  components: {
    LoadMore
  },
  computed: {
    ...mapState(["location", "searchRecords", "user"]),
    isShowSaveSerch() {
      return this.showSaveSerch && this.searchRecords.length;
    }
  },
  methods: {
    getHistoryList() {
      let historyList;
      switch (this.tradeType) {
        case 1:
          historyList = getItem(Constant.RENT_HISTORY) || [];
          break;
        case 3:
          historyList = getItem(Constant.NEW_ESTATE_HISTORY) || [];
          break;
        default:
          historyList = getItem(Constant.SECOND_HISTORY) || [];
      }
      return historyList;
    },
    clearHistory() {
      this.historyList = [];
      this.removeHistoryList(this.tradeType);
    },
    loadMore() {
      if (!this.noMore) {
        this.searchParams.page++;
        this.searchEstate();
      }
    },
    // 高亮
    highlight(str) {
      return str.replace(
        new RegExp(this.estateName, "gi"),
        `<span style="color: #3072F6">${this.estateName}</span>`
      );
    },
    setHistoryList(tradeType, data) {
      switch (tradeType) {
        case 1:
          setItem(Constant.RENT_HISTORY, data);
          break;
        case 3:
          setItem(Constant.NEW_ESTATE_HISTORY, data);
          break;
        default:
          setItem(Constant.SECOND_HISTORY, data);
      }
    },
    removeHistoryList(tradeType) {
      switch (tradeType) {
        case 1:
          removeItem(Constant.RENT_HISTORY);
          break;
        case 2:
          removeItem(Constant.SECOND_HISTORY);
          break;
        case 3:
          removeItem(Constant.NEW_ESTATE_HISTORY);
          break;
      }
    },
    async loadSellestateList() {
      if (this.noMore || this.isloading) return;
      this.isloading = true;
      try {
        const params = {
          ...this.searchParams,
          tradeType: this.tradeType === 4 ? 2 : this.tradeType
        };
        const { data } = await houseServer.listEstateByName(params);
        if (data.records && data.records.length) {
          if (params.page == 1) {
            this.estates = data.records;
          } else if (params.page > 1) {
            this.estates = this.estates.concat(data.records);
          }
          if (data.records.length < this.searchParams.itemsPerPage) {
            this.noMore = true;
          }
        } else {
          this.noMore = true;
          this.showResult = false;
        }
      } catch (error) {
        this.noMore = true;
        console.log("listEstateByName :>>", error);
      } finally {
        this.isloading = false;
      }
    },
    async loadNewEstateList() {
      if (this.noMore || this.isloading) return;
      this.isLoading = true;
      try {
        const params = {
          ...this.searchParams
        };
        const { data } = await houseServer.getNewEstatePage(params);
        if (data.length) {
          // console.log(JSON.stringify(data));
          if (params.page == 1) {
            this.estates = data;
          } else {
            this.estates = this.estates.concat(data);
          }
          if (data.length < params.itemsPerPage) {
            this.noMore = true;
          }
          // console.log(JSON.stringify(this.estates));
        } else {
          this.noMore = true;
          this.showResult = false;
        }
      } catch (error) {
        this.noMore = true;
        console.log("getNewEstatePage :>>", error);
      } finally {
        this.isLoading = true;
      }
    },
    // 推荐楼盘
    async loadRecommendEstate() {
      const params = {
        page: 1,
        itemsPerPage: 5,
        sort: 0,
        cityCode: this.location.cityCode
      };
      const [res, { data }] = await tool.to(
        houseServer.getNewEstatePage(params)
      );
      this.recommendEstate = data || [];
    },
    searchEstate() {
      if (this.tradeType !== 3) {
        this.loadSellestateList();
      } else {
        this.loadNewEstateList();
      }
    },

    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    onClickHistory(item, type) {
      let historyList_ = this.deepCopy(this.historyList);
      historyList_ = historyList_.filter(ele => item.guid !== ele.guid);
      historyList_.unshift(item);
      this.historyList = historyList_;
      this.setHistoryList(this.tradeType, historyList_);
      if (this.tradeType !== 3) {
        this.$router.push({
          path: `/house/${this.tradeType}`,
          query: { estateName: item.name }
        });
      } else {
        if (type === 2)
          this.$router.push({
            path: `/loupan`,
            query: { name: item.name }
          });
      }
      this.updateStoreState({ data: false, key: "showSearchTip" });
    },
    handleSaveSearchItem(index) {
      this.$router.push({
        path: "/house/2",
        query: {
          param: this.searchRecords[index]
        }
      });
      this.updateStoreState({ data: false, key: "showSearchTip" });

      this.$emit("update-search");
    },
    updateStoreState({ key, data }) {
      this.$store.commit("updateState", {
        data,
        key
      });
    },
    enterSetting() {
      if (!this.user) {
        this.updateStoreState({ data: true, key: "showLogin" });
        return;
      }
      this.$router.push({
        path: "/setting",
        query: {
          value: 2
        }
      });
    }
  },
  created() {
    this.historyList = this.getHistoryList();
    that = this;
    this.showReacommedEstate && this.loadRecommendEstate();
  }
};
</script>

<style scoped lang="less">
.input-down {
  width: 100%;
  left: 0;
  top: 40px;
  max-height: 600px;
  overflow: auto;
  font-size: 14px;
  background-color: #fff;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.5);
  z-index: 10;
  transform-origin: left top;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
  transition: all 300ms ease;
  &.show {
    visibility: visible;
    opacity: 1;
    transform: scaleY(1);
  }
  .save-wrap {
    & > li {
      display: block;
      padding: 0 21px;
      line-height: 42px;
      width: 81%;
    }
  }
}
.searchMsgTitle {
  padding: 0 21px;
  line-height: 42px;
  background-color: #f4f7f9;
}
.searchMsgName {
  display: inline-block;
  width: 60%;
  color: #849aae;
}
.searchMsgTitleRightPart {
  display: inline-block;
  width: 40%;
  text-align: right;
}
.manage {
  color: #888c8e;
  display: inline-block;
}
.input-down ul li a {
  display: block;
  padding: 0 21px;
  line-height: 42px;
}
.msgListAdd {
  display: inline-block;
  color: #b0b3b4;
  width: 18%;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
.msgListTitle {
  display: inline-block;
  color: #101d37;
  width: 81%;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sug_region {
  width: 40px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background: #f5f5f5;
  border-radius: 2px;
  font-size: 12px;
  color: #a0a3a5;
  margin-right: 4px;
  display: inline-block;
}

.sub-text {
  font-size: 12px;
  color: #9399a5;
}
.search-result {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 0 0 0 21px;
  font-size: 14px;
  vertical-align: middle;
  box-sizing: border-box;
}
.search-result-list {
  overflow: hidden;
  width: 100%;
  height: 42px;
  line-height: 42px;
}
.resblock-name {
  display: inline-block;
  max-width: 340px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.fr {
  float: right;
}
.price {
  font-size: 14px;
  color: #999;
  vertical-align: middle;
}
.resblock-type {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  font-size: 14px;
  color: #666;
  background: #eee;
  border-radius: 2px;
  margin-left: 5px;
}

.infinite-list-item a:hover {
  background-color: #f4f7f9 !important;
  cursor: pointer;
}
.search-result:hover {
  background-color: #f9f9f9;
}
.search-btn i {
  font-size: 20px;
  color: #4d4d4d;
}
.hot-search-list-item {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 2px;
  vertical-align: middle;
  margin-left: 21px;
  margin-top: 10px;
  a {
    display: inline-block;
    height: 24px;
    padding: 0 5px;
  }
}
</style>

